exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-portfolio-js": () => import("./../../../src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-portfolio-mdx-frontmatter-slug-js-content-file-path-src-pages-portfolio-project-1-index-mdx": () => import("./../../../src/pages/portfolio/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/ninabohon/Documents/dev/portfolio23/src/pages/portfolio/project-1/index.mdx" /* webpackChunkName: "component---src-pages-portfolio-mdx-frontmatter-slug-js-content-file-path-src-pages-portfolio-project-1-index-mdx" */),
  "component---src-pages-portfolio-mdx-frontmatter-slug-js-content-file-path-src-pages-portfolio-project-2-index-mdx": () => import("./../../../src/pages/portfolio/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/ninabohon/Documents/dev/portfolio23/src/pages/portfolio/project-2/index.mdx" /* webpackChunkName: "component---src-pages-portfolio-mdx-frontmatter-slug-js-content-file-path-src-pages-portfolio-project-2-index-mdx" */),
  "component---src-pages-portfolio-mdx-frontmatter-slug-js-content-file-path-src-pages-portfolio-project-3-index-mdx": () => import("./../../../src/pages/portfolio/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/ninabohon/Documents/dev/portfolio23/src/pages/portfolio/project-3/index.mdx" /* webpackChunkName: "component---src-pages-portfolio-mdx-frontmatter-slug-js-content-file-path-src-pages-portfolio-project-3-index-mdx" */),
  "component---src-pages-portfolio-mdx-frontmatter-slug-js-content-file-path-src-pages-portfolio-project-4-index-mdx": () => import("./../../../src/pages/portfolio/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/ninabohon/Documents/dev/portfolio23/src/pages/portfolio/project-4/index.mdx" /* webpackChunkName: "component---src-pages-portfolio-mdx-frontmatter-slug-js-content-file-path-src-pages-portfolio-project-4-index-mdx" */),
  "component---src-pages-portfolio-project-1-index-mdx": () => import("./../../../src/pages/portfolio/project-1/index.mdx" /* webpackChunkName: "component---src-pages-portfolio-project-1-index-mdx" */),
  "component---src-pages-portfolio-project-2-index-mdx": () => import("./../../../src/pages/portfolio/project-2/index.mdx" /* webpackChunkName: "component---src-pages-portfolio-project-2-index-mdx" */),
  "component---src-pages-portfolio-project-3-index-mdx": () => import("./../../../src/pages/portfolio/project-3/index.mdx" /* webpackChunkName: "component---src-pages-portfolio-project-3-index-mdx" */),
  "component---src-pages-portfolio-project-4-index-mdx": () => import("./../../../src/pages/portfolio/project-4/index.mdx" /* webpackChunkName: "component---src-pages-portfolio-project-4-index-mdx" */)
}

